import cx from 'classnames';
import React from 'react';

import * as styles from './Orbit.module.css';

const Orbit = ({
	children,
	spacing = 'md',
	uniformHeadings,
	uniformButtons,
	as: Component = 'div',
	className = '',
}: {
	children: React.ReactNode;
	spacing?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	uniformHeadings?: boolean;
	uniformButtons?: boolean;
	as?: React.ElementType;
	className?: string;
}) => {
	return (
		<Component
			className={cx(styles.orbit, styles[`${spacing}Spacing`], {
				[styles.uniformHeadings]: uniformHeadings,
				[styles.uniformButtons]: uniformButtons,
				[className]: !!className,
			})}>
			{children}
		</Component>
	);
};

export default Orbit;
