import { LINKS } from '@utils/constants';
import { SVGPicker } from '@utils/helpers';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React from 'react';

import Box from '@components/Box/Box';
import Hero from '@components/Hero/Hero';
import Content from '@components/Layout/Content/Content';
import Grid from '@components/Layout/Grid/Grid';
import Lead from '@components/Layout/Lead/Lead';
import Orbit from '@components/Layout/Orbit/Orbit';
import PageSection from '@components/Layout/PageSection/PageSection';
import ApplicationListing from '@components/Listing/ApplicationListing';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Breadcrumbs from '@components/Molecule/Breadcrumbs/Breadcrumbs';
import Card from '@components/Molecule/Card/Card';
import EmbedVideo from '@components/Molecule/Video/EmbedVideo';
import Copy from '@components/Typography/Copy';
import { MotionCopy } from '@components/Typography/Copy';
import H from '@components/Typography/H';
import { MotionH } from '@components/Typography/H';

const ApplicationTemplate = ({ pageContext }: { pageContext: any }) => {
	const { crumbs } = pageContext.breadcrumb;

	const { uid } = pageContext.application;
	const {
		title,
		summary,
		lead,
		content_sections,
		application_image,
		relevant_categories,
		seo_title,
		seo_description,
		seo_image,
	} = pageContext.application.data;

	const videos = pageContext.videos;

	const filteredContentSections = content_sections.filter((section) =>
		section?.content?.html !== null || section?.key_features?.html !== null
			? section
			: null
	);

	const filteredRelevantCategories = relevant_categories.filter(
		({ category }) => (category.document !== null ? category : null)
	);

	return (
		<Page transparent={true} transparentBorder={true}>
			<SEO
				title={`${
					seo_title ? seo_title : title ? title : null
				} | UHV Design`}
				description={seo_description && seo_description}
				image={seo_image && seo_image}
			/>

			{application_image?.gatsbyImageData && (
				<Hero
					image={{
						src: getImage(application_image?.gatsbyImageData),
						alt: application_image?.alt
							? application_image?.alt
							: '',
					}}>
					<div className="column--space">
						<Breadcrumbs crumbs={crumbs} />

						<Content>
							<MotionCopy weight="bold">
								<Link to={LINKS.APPLICATIONS}>
									Applications
								</Link>
							</MotionCopy>

							{title && (
								<MotionH level={1} spacing="md">
									{title}
								</MotionH>
							)}

							{summary?.html && (
								<Copy spacing="xl" weight="bold" as="div">
									<motion.div
										initial="hidden"
										whileInView="visible"
										viewport={{ once: true, amount: 0.6 }}
										transition={{ duration: 0.35 }}
										variants={{
											visible: {
												opacity: 1,
												translateY: 0,
											},
											hidden: {
												opacity: 0,
												translateY: '1rem',
											},
										}}
										dangerouslySetInnerHTML={{
											__html: summary.html,
										}}></motion.div>
								</Copy>
							)}
						</Content>
					</div>
				</Hero>
			)}

			{lead?.html && (
				<PageSection background="grey">
					<Content constrict uniformHeadings>
						<motion.div
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true, amount: 0.6 }}
							transition={{ duration: 0.35 }}
							variants={{
								visible: {
									opacity: 1,
									translateY: 0,
								},
								hidden: {
									opacity: 0,
									translateY: '1rem',
								},
							}}
							dangerouslySetInnerHTML={{
								__html: lead?.html,
							}}></motion.div>
					</Content>
				</PageSection>
			)}

			{filteredContentSections?.length > 0 &&
				filteredContentSections.map(
					(
						{
							content_title,
							content,
							key_features,
						}: {
							content_title: string;
							content: {
								html: string;
							};
							key_features: string;
						},
						i: number
					) => {
						const isBrowser = typeof window !== 'undefined';

						if (!isBrowser) return null;
						// Maps the string into DOM
						const doc = new DOMParser().parseFromString(
							content.html,
							'text/html'
						);

						// Finds any video elements embeded by Prismic & replaces Prismic's iframe with our own 'EmbedVideo' component
						const elements = [...doc.body.childNodes].map(
							(child, i) => {
								if (
									child.outerHTML.includes(
										`data-oembed-type="video"`
									) ||
									child.textContent.includes(
										`data-oembed-type="video"`
									)
								) {
									// Match the src of the found element, with the src of a context object to locate the correct poster
									const src =
										child.getAttribute('data-oembed');

									const selectedVideo = videos.filter(
										(obj: { src: string }) =>
											obj.src === src ? obj : null
									)[0];

									return (
										<EmbedVideo
											key={i}
											src={src}
											title=""
											poster={selectedVideo.poster}
										/>
									);
								}
								return child.outerHTML || child.textContent;
							}
						);

						return (
							<PageSection key={i}>
								<Orbit
									spacing="xl"
									uniformHeadings
									uniformButtons>
									{content_title && (
										<Lead>
											<MotionH level={3} as="h2">
												{content_title}
											</MotionH>
										</Lead>
									)}

									{elements && (
										<motion.div
											initial="hidden"
											whileInView="visible"
											viewport={{
												once: true,
												amount: 0.05,
											}}
											transition={{
												duration: 0.35,
											}}
											variants={{
												visible: {
													opacity: 1,
													translateY: 0,
												},
												hidden: {
													opacity: 0,
													translateY: '1rem',
												},
											}}>
											{elements.map((element, i) => (
												<React.Fragment key={i}>
													{typeof element ===
													'string' ? (
														parse(element)
													) : (
														<div
															style={{
																marginTop:
																	'1.5rem',
															}}>
															{element}
														</div>
													)}
												</React.Fragment>
											))}
										</motion.div>
									)}

									{key_features?.html && (
										<Box>
											<Content spacing="sm">
												<H level={3}>Key features</H>

												<div
													dangerouslySetInnerHTML={{
														__html: key_features.html,
													}}></div>
											</Content>
										</Box>
									)}
								</Orbit>
							</PageSection>
						);
					}
				)}

			<PageSection>
				<Copy weight="bold" font="xl2" align="center" prose={true}>
					If you would like to discuss your project with our
					engineering team, please{' '}
					<Link to={LINKS.CONTACT} className="underline">
						contact us
					</Link>
					.
				</Copy>
			</PageSection>

			{filteredRelevantCategories?.length > 0 && (
				<PageSection>
					<MotionH level={2}>
						Related products suited to this application
					</MotionH>

					<Grid gap="sm">
						{filteredRelevantCategories.map(
							({ category }, key: number) => {
								if (!category?.document) return;

								const { uid } = category.document;
								const { title } = category.document.data;

								return (
									<Card
										key={key}
										title={title}
										large={true}
										to={`${LINKS.PRODUCTS}/${uid}`}
										svg={title && SVGPicker(uid)}>
										{title && (
											<H level={5} as="span">
												{title}
											</H>
										)}
									</Card>
								);
							}
						)}
					</Grid>
				</PageSection>
			)}

			<PageSection>
				<MotionH level={2}>Other applications</MotionH>

				<ApplicationListing large={true} exclude={uid} />
			</PageSection>
		</Page>
	);
};

export default ApplicationTemplate;
