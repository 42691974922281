// extracted by mini-css-extract-plugin
export var lgSpacing = "Orbit-module--lg--spacing--AeO1G";
export var mdSpacing = "Orbit-module--md--spacing--B+Yxh";
export var noneSpacing = "Orbit-module--none--spacing--yxfc2";
export var orbit = "Orbit-module--orbit--9hMkG";
export var rightBounce = "Orbit-module--right-bounce--6T0X0";
export var smSpacing = "Orbit-module--sm--spacing--17UUK";
export var uniformButtons = "Orbit-module--uniformButtons--vExz3";
export var uniformHeadings = "Orbit-module--uniformHeadings--dkYwX";
export var xlSpacing = "Orbit-module--xl--spacing--ru4bB";
export var xsSpacing = "Orbit-module--xs--spacing--rG-s1";